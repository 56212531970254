import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import axios from "../../plugins/axios";
import MyCKEditor from "../../ckeditor/MyCKEditor";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/configReducer";
import InsertPlaceholder from "./InsertPlaceholder";
import axios_raw from "axios";
import {
    Box,
    Button,
    Button as MuiIconButton,
    TextField,
    Switch,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";

import { Save as SaveIcon } from "@material-ui/icons";

import { grey } from "@material-ui/core/colors";
import ckcss from "../../ckeditor/ck-content.css";
const IconButton = styled(MuiIconButton)`
  padding: 2px;
  margin-top: -2px;
  margin-left: 2px;
  min-width: 0;
  color: ${grey[700]};
`;

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const SectionEditor = ({ section, setSection, onSave, sections, getSection }) => {
    const [sectionInfo, setSectionInfo] = useState(section);
    const [saving, setSaving] = useState(false);
    const [editor, setEditor] = useState();
    const [dependsOnFilter, setDependsOnFilter] = useState("");

    const styles = { targetDiv: { height: "calc(100vh - 240px)" } };

    const thisSection = useMemo(() => {
        return sections.find(s => s.sectionId === section.sectionId);
    }, [section.sectionId, sections]);    

    useEffect(() => {

        let html = "";  
                
        if (thisSection)
            html = thisSection.html || "LOADING ...";

        if (editor)
            editor.setData(html);
    }, [editor, thisSection]);

    useEffect(() => {
        if (section.sectionId && !section.html)            
            getSection(section.sectionId);        
    
    }, [getSection, section, section.sectionId, section.html]);    

    const filteredSections = useMemo(() => {

        let term = dependsOnFilter.trim().toLowerCase();

        if (!term)
            return sections;

        return sections.filter(s => s.key.toLowerCase().includes(dependsOnFilter));

    }, [sections, dependsOnFilter]);

    const setSectionKey = (e) => {
        setSectionInfo({ ...sectionInfo, key: e.target.value });
    };

    const setSectionQuestion = (e) => {
        setSectionInfo({ ...sectionInfo, question: e.target.value });
    };

    const setSectionMandatory = (e) => {
        console.log(e);
        setSectionInfo({ ...sectionInfo, isMandatory: e.target.checked });
    };

    const setDependsOn = (e) => {
        console.log('dep', e.target.value);
        setSectionInfo({ ...sectionInfo, dependsOnSectionId: e.target.value });
    };

    const save = async () => {

        let html = editor.getData();

        if (html === sectionInfo.html && sectionInfo.key === section.key && sectionInfo.question === section.question &&
            sectionInfo.isMandatory === section.isMandatory && sectionInfo.dependsOnSectionId === section.dependsOnSectionId) {
            onSave();
            return;
        }

        var saveData = {
            ...sectionInfo,
            html,
        };

        setSaving(true);

        var response = await axios.post(`${apiUrl}/savesection`, saveData);

        setSection(response.data);

        console.log('saving!');
        setSaving(false);

        onSave();
    };

    const downloadWord = async () => {       

        const margins = editor.config.get('pagination.pageMargins');    

        let converterOptions = {
            margin_right: margins.right,
            margin_left: margins.left,
            margin_top: margins.top,
            margin_bottom: margins.bottom,
            //header: [
            //    {
            //        html: '<p><img src="https://ewdatastore.blob.core.windows.net/images/worknesths.png"/></p>',
            //        css: 'p { text-align: center; }'
            //    }
            //],
            footer: [
                {
                    html: '<span></span>',
                    type: 'first'
                },
                {
                    html: '<p><span class="pageNumber"></span></p>',
                    css: 'p { font-size: 1em; color: hsl(0, 0%, 60%); text-align: center; margin-bottom:15px;}'
                }
            ]
        };        

        let response = await axios_raw.post(
            "https://ckeditor-docx.greenwave-257981d3.westeurope.azurecontainerapps.io/v1/convert",
            {
                html: `<html><link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Raleway:900"><body><div class="ck-content">${editor.getData()}</div></body></html>`,
                css: ckcss,
                options: converterOptions,
            },
            { responseType: "blob" }
        );

        console.log(response);

        const blob = new Blob([response.data]);

        var link = window.document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("target", "_blank");
        link.download = `${sectionInfo.key}.docx`;
        link.click();
    };

    const downloadPdf = async () => {

        const margins = editor.config.get('pagination.pageMargins');

        let converterOptions = {
            margin_right: margins.right,
            margin_left: margins.left,
            margin_top: margins.top,
            margin_bottom: margins.bottom,
            //header_html: `<div class="ck-content"><figure class="image image_resized" style="width:100%;"><img src="https://ewdatastore.blob.core.windows.net/images/worknesths.png"/></figure></div>`,
            footer_html: '<div class="styled-counter"><span class="pageNumber"></span></div>',
            header_and_footer_css: ckcss//'.styled-counter { font-size: 1em; color: hsl(0, 0%, 60%); text-align: center; margin-bottom:15px;}',
        };        

        let response = await axios_raw.post(
            "https://ckeditor-pdf.greenwave-257981d3.westeurope.azurecontainerapps.io/v1/convert",
            {
                html: `<html><link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Raleway:900"><body><div class="ck-content">${editor.getData()}</div></body></html>`,
                css: ckcss,
                options: converterOptions,
            },
            { responseType: "blob" }
        );

        console.log(response);

        const blob = new Blob([response.data]);

        var link = window.document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("target", "_blank");
        link.download = `${sectionInfo.key}.pdf`;
        link.click();
    };

    return (
        <React.Fragment>
            <Box display="flex" flexDirection="column" style={styles.targetDiv} >
                <Box display="flex" alignItems="center" gridGap="5px">
                    <TextField
                        variant="outlined"
                        size="small"
                        label="Section Key"
                        inputProps={{ maxLength: 50 }}
                        value={sectionInfo.key || ""}
                        onChange={setSectionKey}
                        m={2}
                        style={{ width: "300px" }}
                    />
                    <TextField
                        variant="outlined"
                        size="small"
                        label="Question"
                        inputProps={{ maxLength: 255 }}
                        value={sectionInfo.question || ""}
                        onChange={setSectionQuestion}
                        m={2}
                        style={{ width: "300px" }}
                    />
                    <FormControl variant="outlined" margin="dense" style={{margin: 0}}>
                        <InputLabel id="demo-simple-select-label">Depends on section</InputLabel>
                        <Select
                            style={{ width: '250px' }}
                            displayEmpty
                            value={sectionInfo.dependsOnSectionId || ""}
                            label="Depends on section"
                            fullWidth
                            onChange={setDependsOn}
                        >
                            <MenuItem>
                                <TextField
                                    onKeyDown={e => { e.stopPropagation(); }}
                                    onClick={e => { e.stopPropagation(); }}
                                    fullWidth
                                    value={dependsOnFilter}
                                    onChange={e => setDependsOnFilter(e.target.value)}
                                />
                            </MenuItem>
                            <MenuItem value={null}>None</MenuItem>
                            {
                                filteredSections.map(s => <MenuItem key={s.sectionId} value={s.sectionId}>{s.key} - {s.sectionId}</MenuItem>)
                            }
                        </Select>                        
                    </FormControl>          
                    <FormControlLabel
                        control={
                            <Switch
                                checked={sectionInfo.isMandatory}
                                onChange={setSectionMandatory}
                            />
                        }
                        label="Mandatory"
                    />
                    {
                        editor &&
                        <IconButton disabled={saving || (thisSection?.sectionId && !thisSection?.html)} disableRipple={false}>
                            <SaveIcon onClick={save} />
                        </IconButton>
                    }
                </Box>
                <Box display="flex" alignItems="center">
                    <InsertPlaceholder editor={editor} />
                    <Button variant="contained" style={{ marginLeft: '10px' }} onClick={downloadWord}>Download Word</Button>
                    <Button variant="contained" style={{ marginLeft: '10px' }} onClick={downloadPdf}>Download PDF</Button>
                </Box>
                <MyCKEditor type="section" setEditor={setEditor} editor={editor} />                
            </Box>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    sections: Object.values(state.configReducer.sections)
});
const mapDispatchToProps = (dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SectionEditor);
