import React from 'react';
import {
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow, 
    TableContainer,    
    makeStyles,
} from "@material-ui/core";
import {
    useGetStatsQuery,
} from "../../store/pardotApiSlice";
import { format } from "date-fns";
const useStyles = makeStyles({
    container: {
        height: "100%",
        flex: 1,
        maxHeight: "calc(100vh - 205px)",
    }, 
    table:
    {
        '& td,th': {
            textAlign:'center'
        }
    }
});

const Stats = () => {

    const classes = useStyles();
    const { data: stats } = useGetStatsQuery();

    if (!stats)
        return <h2 style={{ margin: '10px' }}>Loading ...</h2>;
       
    return (
        <>
            <TableContainer className={classes.container}>
                <Table stickyHeader className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            {stats.users.map(u => <TableCell key={u}>{u}</TableCell>)}
                            <TableCell>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stats.dates.map(d =>
                            <TableRow key={d}>    
                                <TableCell>{format(new Date(d), "dd/MM/yy")}</TableCell>
                                {stats.users.map(u =>
                                    <TableCell key={u}>{stats.entries.find(e => e.date === d && e.user === u)?.count}</TableCell>
                                )}
                                <TableCell>{stats.entries.filter(e => e.date === d).map(e => e.count).reduce((a, b) => a + b)}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>             
        </>
              
    )
};

export default Stats;
