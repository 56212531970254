import React, { useMemo, useCallback, useState } from 'react';
import {
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow, 
    TableContainer,
    Chip,
    IconButton,
    Box,
    Menu,
    MenuItem,
    makeStyles,
} from "@material-ui/core";
import {
    useGetBDEMappingsQuery,
    useGetUsersQuery,
    useUpdateBDEMappingMutation
} from "../../store/pardotApiSlice";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles({
    container: {
        height: "100%",
        flex: 1,
        maxHeight: "calc(100vh - 205px)",
    }, 
    table:
    {
        '& td': {
            verticalAlign:'top'
        }
    }
});

const BDEMappings = () => {

    const classes = useStyles();
    const { data: mappings } = useGetBDEMappingsQuery();
    const { data: users } = useGetUsersQuery();

    const [anchorEl, setAnchorEl] = useState(null);
    const [menuIndustry, setMenuIndustry] = useState(null);
    const [menuProfile, setMenuProfile] = useState(null);

    const [updateMapping, { isLoading: mappingUpdating }] = useUpdateBDEMappingMutation();

    const profiles = useMemo(() => {

        if (!mappings)
            return [];

        let list = [...new Set(mappings.map(item => item.employeeProfile))].sort();

        return list;
    }, [mappings]);   

    const industries = useMemo(() => {

        if (!mappings)
            return [];

        let list = [...new Set(mappings.map(item => item.industry))].sort();

        return list;
    }, [mappings]);

    const userIds = useCallback((industry, profile) => {

        let ids = mappings
            .filter(m => m.industry === industry && m.employeeProfile === profile)
            .map(m => m.salesforceUserId);        

        return ids;
    }, [mappings]);

    const userName = useCallback(id => {
        if (!users)
            return null;       

        var u = users.find(x => x.id === id);

        return u?.name || id;
    }, [users]);

    const deleteMapping = useCallback((industry, profile, userId) => {
        console.log({ mappings });
        updateMapping({ industry, profile, userId, isActive: false });
    }, [mappings, updateMapping]);

    const addMapping = useCallback((userId) => {
        updateMapping({ industry: menuIndustry, profile:menuProfile, userId, isActive: true });
        handleCloseMenu();
    }, [menuIndustry, menuProfile, updateMapping]);

    const handleOpenMenu = (target, industry, profile) => {
        setAnchorEl(target);
        setMenuIndustry(industry);
        setMenuProfile(profile)
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenuIndustry(null);
        setMenuProfile(null);
    };

    var bdes = useMemo(() => {
        if (!users)
            return [];
        return users.filter(u => u.isActive && u.userRoleId === "00E0Y000000dhmhUAA").sort((a, b) => a.name > b.name ? 1 : -1);
    }, [users]);

    const menuUsers = useMemo(() => {
        var users = bdes
            .filter(u => !userIds(menuIndustry, menuProfile).includes(u.id));
        
        return users;
    }, [bdes, menuIndustry, menuProfile, userIds]);
    
    return (
        <>
            <TableContainer className={classes.container}>
                <Table stickyHeader className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {profiles.map(p => <TableCell key={p}>{p}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {industries.map(i =>
                            <TableRow key={i}>
                                <TableCell>{i}</TableCell>
                                {profiles.map(p =>
                                    <TableCell key={p}>
                                        <Box display="flex" flexDirection="column" gridGap="2px">
                                            {userIds(i, p).map(id =>
                                                <Box key={id}>
                                                    <Chip
                                                        size="small"
                                                        disabled={mappingUpdating}
                                                        onDelete={() => deleteMapping(i, p, id)}
                                                        label={userName(id)}
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => handleOpenMenu(e.currentTarget, i, p)}
                                            disabled={mappingUpdating}
                                        >
                                            <AddIcon fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>  
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                {menuUsers
                    .map(u => <MenuItem key={u.id} onClick={() => addMapping(u.id)}>{u.name}</MenuItem>)
                }
            </Menu>           
        </>
              
    )
};

export default BDEMappings;
