import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions as snackActions } from "./store/snackReducer";
import "./custom.css";
import axios from "./plugins/axios";
import { actionCreators as configActions } from "./store/configReducer";
import { actionCreators as userActions } from "./store/userReducer";
import authService from "./plugins/authService";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Routes from "./routes/Routes";
import Helmet from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import maTheme from "./theme";
import history from "./routes/history";
import LandingPage from "./pages/LandingPage";
import TokenCheckerDialog from "./components/TokenCheckerDialog";
import { QueryClientProvider } from "react-query";
import reactQueryClient from "./utils/reactQueryClient";
import { ReactQueryDevtools } from "react-query/devtools";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const App = ({ snackState, removeSnack, setServerValues, userState }) => {
  useEffect(() => {
    async function initialiseUserSession() {
      try {
        const response = await axios.get(`${apiUrl}/vars`);
        setServerValues(response.data);

        await authService.initialise();
        await authService.handleAuthCallback();

        const redirect = localStorage.getItem("redirect");
        if (redirect != null) {
          history.push(redirect);
          localStorage.removeItem("redirect");
        }
      } catch (error) {
        console.error(error);
      }
    }
    initialiseUserSession();
  }, [setServerValues]);

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Worknest Tools"
        defaultTitle="WorkNest Tools"
      />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[0]}>
            <ThemeProvider theme={maTheme[0]}>
              <QueryClientProvider client={reactQueryClient}>
                {userState.user ? <Routes /> : <LandingPage />}
                <TokenCheckerDialog />
                <ReactQueryDevtools />
              </QueryClientProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>

        <Snackbar
          style={{
            height:
              snackState.snack.severity === "warning" ? "100%" : "default",
          }}
          open={snackState.open}
          autoHideDuration={6000}
          onClose={removeSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={removeSnack} severity={snackState.snack.severity}>
            {snackState.snack.message}
          </Alert>
        </Snackbar>
      </StylesProvider>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  snackState: state.snackReducer,
  userState: state.userReducer,
  configState: state.configReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(configActions, dispatch),
    ...bindActionCreators(userActions, dispatch),
    removeSnack: () => dispatch(snackActions.removeSnack()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
