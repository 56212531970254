import * as types from "./constants";
import _ from "lodash";

import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const initialState = {
    serverValues: {},
    placeholders: [],
    templates: {},
    sections: {},
    documentTempateId: null,
};

export const actionCreators = {
    setServerValues: (values) => async (dispatch) => {
        dispatch({ type: types.SET_SERVER_VALUES, values });
    },
    getTemplateInfo: () => async (dispatch) => {
        var response = await axios.get(`${apiUrl}/gettemplateinfo`);

        dispatch({ type: types.SET_TEMPLATE_INFO, payload: response.data });
    },
    setTemplate: (template) => async (dispatch) => {
        dispatch({ type: types.SET_TEMPLATE, template });
    },
    setSection: (section) => async (dispatch) => {
        dispatch({ type: types.SET_SECTION, section });
    },
    setDocumentTemplateId: (id) => async (dispatch) => {
        dispatch({ type: types.SET_DOCUMENT_TEMPLATE_ID, id });
    },
    getSection: (sectionId) => async (dispatch) => {  
        try {
            var response = await axios.get(`${apiUrl}/getsection/${sectionId}`);

            dispatch({ type: types.SET_SECTION, section: response.data });
        }
        catch (err) {

            setTimeout(async () => {
                response = await axios.get(`${apiUrl}/getsection/${sectionId}`);

                dispatch({ type: types.SET_SECTION, section: response.data });
            }, 1000);            
        }        
    }
 };

const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === types.SET_SERVER_VALUES) {
        return {
            ...state,
            serverValues: action.values,
        };
    }

    if (action.type === types.SET_TEMPLATE_INFO) {
        return {
            ...state,
            placeholders: action.payload.placeholders,
            templates: _.keyBy(action.payload.templates, (x) => x.templateId),
            sections: _.keyBy(action.payload.sections, (x) => x.sectionId),
        };
    }

    if (action.type === types.SET_TEMPLATE) {
        return {
            ...state,
            templates: {
                ...state.templates,
                [action.template.templateId]: action.template,
            },
        };
    }

    if (action.type === types.SET_SECTION) {
        return {
            ...state,
            sections: {
                ...state.sections,
                [action.section.sectionId]: action.section
            },
        };
    }

    if (action.type === types.SET_DOCUMENT_TEMPLATE_ID) {
        return {
            ...state,
            documentTemplateId: action.id,
        };
    }    

    return state;
};

export default reducer;
