import React, { useState, useMemo } from 'react';
import { format } from "date-fns";
import {
    Box,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    TextField,
} from "@material-ui/core";
import { useGetListsQuery } from "../../store/pardotApiSlice";

const Lists = ({ setList, filter, setFilter }) => {

    const { data: lists } = useGetListsQuery();
    const [page, setPage] = useState(0);

    const activeFilter = useMemo(() => {
        if (filter.length < 3)
            return "";

        setPage(0);

        return filter.toLowerCase();
    }, [filter]);    

    const rowsPerPage = 10;

    const sortedLists = useMemo(() => {
        if (!lists)
            return [];

        let sorted = [...lists];

        return sorted.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);
    }, [lists]);

    const selectList = (l) => {
        //if (l.memberCount > 2000)
        //    return;

        setList(l);
    }

    const filteredLists = useMemo(() => {

        let filtered = sortedLists;

        if (activeFilter !== "")
            filtered = filtered.filter(f => f.name.toLowerCase().includes(activeFilter));

        return filtered;

    }, [activeFilter, sortedLists]);

    const displayedLists = useMemo(() => {

        let displayed = filteredLists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        return displayed;
    }, [filteredLists, page]); 

    return (
        <>
            <Box>
                <TextField
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    variant="outlined"
                    size="small"
                    label="filter"
                />
            </Box>            
            {!lists && <h2 style={{ margin: '10px' }}>Loading ...</h2>}
            {lists && lists.length === 0 && <h2 style={{ margin: '10px' }}>No entries</h2>}
            {lists && lists.length > 0 &&
                <Table size="small">
                    <TableHead>
                        <TableRow style={{height:'37px'}}>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Members</TableCell>
                            <TableCell>Updated</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedLists.map(l =>
                            <TableRow key={l.id}>
                                <TableCell>{l.id}</TableCell>
                                <TableCell style={{ cursor: 'pointer' }} onClick={() => { selectList(l); }}>{l.name}</TableCell>
                                <TableCell>{l.memberCount}</TableCell> 
                                <TableCell>{format(new Date(l.updatedAt), "dd/MM/yy HH:mm")}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                count={filteredLists.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(e, newPage) => setPage(newPage)}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            }          
        </>
    )
};

export default Lists;
