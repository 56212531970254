import { createApi } from '@reduxjs/toolkit/query/react'
import axiosInstance from "../plugins/axios";

const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async ({ url, method, data, params, headers }) => {
            try {
                const result = await axiosInstance({
                    url: baseUrl + url,
                    method,
                    data,
                    params,
                    headers,
                });

                return { data: result.data }
            } catch (err) {
                console.error(err);
                return {
                    error: {
                        status: err.response?.status,
                        data: err.response?.data || err.message,
                    },
                }
            }
        }

export const pardotApi = createApi({
    baseQuery: axiosBaseQuery({
        baseUrl: process.env.REACT_APP_WORKNEST_TOOLS_API_URL
    }),
    tagTypes: ['JobTitles', 'Prospects'],
    endpoints: build => ({
        getLists: build.query({
            query: () => ({ url: '/pardot/lists', method: 'get' })
        }),
        getStats: build.query({
            query: () => ({ url: '/pardot/stats', method: 'get' })
        }),
        getForms: build.query({
            query: () => ({ url: '/pardot/forms', method: 'get' })
        }),
        updateForm: build.mutation({
            query: formTagRequest => ({
                url: '/pardot/updateformtags',
                method: 'POST',
                data: formTagRequest
            }),
            async onQueryStarted(request, { dispatch, queryFulfilled }) {

                let patchResult = dispatch(
                    pardotApi.util.updateQueryData('getForms', undefined, (draft) => {
                        return draft.map(f => {

                            if (f.id !== request.formId)
                                return f;

                            if (!request.isActive && f.tags.includes(request.tag))
                                return { ...f, tags: f.tags.filter(tag => tag !== request.tag) };

                            if (request.isActive && !f.tags.includes(request.tag))
                                return { ...f, tags: [...f.tags, request.tag] };

                            return f;
                        });
                    })
                );

                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo();
                }
            },
        }),
        getJobTitles: build.query({
            query: () => ({ url: '/pardot/jobtitles', method: 'get' }),
            providesTags: (result) =>
                result
                    ?
                    [
                        ...result.map(({ title }) => ({ type: 'JobTitles', title })),
                        { type: 'JobTitles', title: 'LIST' },
                    ]
                    :
                    [{ type: 'JobTitles', title: 'LIST' }],
        }),
        updateJobTitle: build.mutation({
            query: updatedJobTitle => ({
                url: '/pardot/updatejobtitle',
                method: 'POST',
                data: updatedJobTitle
            }),
            async onQueryStarted(request, { dispatch, queryFulfilled }) {

                let patchResult = dispatch(
                    pardotApi.util.updateQueryData('getJobTitles', undefined, (draft) => {

                        let match = draft.find(jt => jt.title === request.title)

                        if (match)
                            return draft.map(jt => jt.title === request.title ? request : jt)
                        else
                            return [...draft, request];                         
                    })
                );

                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo();
                }
            },
        }),
        updateJobTitles: build.mutation({
            query: formData => ({
                url: '/pardot/updatejobtitles',
                method: 'POST',
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }),
            invalidatesTags: [{ type: 'JobTitles', title: 'LIST' }],
        }),        
        getUsers: build.query({
            query: () => ({ url: '/pardot/users', method: 'get' })
        }),
        getBDEMappings: build.query({
            query: () => ({ url: '/pardot/bdemappings', method: 'get' })
        }),
        updateBDEMapping: build.mutation({
            query: bdeMappingRequest => ({
                url: '/pardot/updatebdemappings',
                method: 'POST',
                data: bdeMappingRequest
            }),
            async onQueryStarted(request, { dispatch, queryFulfilled }) {

                let patchResult = dispatch(
                    pardotApi.util.updateQueryData('getBDEMappings', undefined, (draft) => {

                        return request.isActive
                            ? [...draft, {
                                salesforceUserId: request.userId,
                                employeeProfile: request.profile,
                                industry: request.industry
                            }]
                            : draft.filter(m => m.salesforceUserId !== request.userId || m.employeeProfile !== request.profile || m.industry !== request.industry)
                    })
                );

                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo();
                }
            },
        }),
        getProspects: build.query({
            query: ({ formId, listId, previousDays }) => ({ url: `/pardot/prospects?formId=${formId || ""}&listId=${listId || ""}&previousDays=${previousDays}`, method: 'get' }),
            providesTags: (result) =>
                result
                    ?
                    [
                        ...result.map(({ id }) => ({ type: 'Prospects', id })),
                        { type: 'Prospects', id: 'LIST' },
                    ]
                    :
                    [{ type: 'Prospects', id: 'LIST' }],
        }),
        updateProspects: build.mutation({
            query: request => ({
                url: '/pardot/updateprospect',
                method: 'POST',
                data: request
            }),
            async onQueryStarted(request, { dispatch, queryFulfilled, getState }) {

                let queries = getState().api.queries;               
                let patchResults = [];

                for (let q in queries) {

                    let info = queries[q];
                    if (info.endpointName === 'getProspects') {

                        patchResults.push(dispatch(
                            pardotApi.util.updateQueryData('getProspects', info.originalArgs, (draft) => {
                                let updateIds = request.updates.map(u => u.id);
                                return draft.map(g => {
                                    if (!g.prospects.some(p => updateIds.includes(p.id)))
                                        return g;

                                    let prospects = g.prospects.map(p => {
                                        let update = request.updates.find(u => u.id === p.id);
                                        return update || p;
                                    });

                                    return {...g, prospects };
                                });
                            })
                        ));
                    }
                }

                try {
                    let result = await queryFulfilled;
                    console.log({ result });


                    for (let error of result.data) {

                        for (let q in queries) {

                            let info = queries[q];
                            if (info.endpointName === 'getProspects') {
                               
                                dispatch(
                                    pardotApi.util.updateQueryData('getProspects', info.originalArgs, (draft) => {                                        
                                        return draft.map(p => p.id === error.key ? ({ ...p, error: error.value }) : p);
                                    })
                                )
                            }
                        }                        
                    
                    }
                } catch {
                    patchResults.forEach(r => r.undo());
                }               
                
            },
        }),
    })
})

export const {
    useGetListsQuery,
    useGetStatsQuery,
    useGetFormsQuery,
    useGetJobTitlesQuery,
    useGetUsersQuery,
    useGetBDEMappingsQuery,
    useGetProspectsQuery,
    useUpdateJobTitleMutation,
    useUpdateJobTitlesMutation,
    useUpdateProspectsMutation,
    useUpdateFormMutation,
    useUpdateBDEMappingMutation,
} = pardotApi