import * as types from "./constants";
import { startOfMonth, formatISO, addMonths } from "date-fns";
import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const initialState = {
    pillarId: null,
    areaId: 0,
    loading: false,
    targets: [],
    pillars: [],
    areas: [],
    selectedDate: addMonths(startOfMonth(new Date()), -1),
    view: "balanced",
    editingTarget: null,
    editingType: null,
};

const getTargets = () => async (dispatch, getState) => {
    let state = getState().targetReducer;

    const formattedDate = formatISO(state.selectedDate, {
        representation: "date",
    });

    dispatch({ type: types.SET_LOADING, loading: true });

    var response = await axios.get(`${apiUrl}/gettargets/${formattedDate}`);
    let targets = response.data.targets;
    let pillars = response.data.pillars;
    let areas = response.data.areas;

    dispatch({ type: types.SET_PILLARS, pillars });
    dispatch({ type: types.SET_AREAS, areas });
    dispatch({ type: types.SET_TARGETS, targets });
};

export const actionCreators = {
    getTargets,

    completeAction: (targetId) => async (dispatch, getState) => {

        let state = getState().targetReducer;

        const formattedDate = formatISO(state.selectedDate, { representation: 'date' });

        dispatch({ type: types.SET_LOADING, loading: true });

        await axios.get(`${apiUrl}/completeAction/${formattedDate}/${targetId}`);

        dispatch(getTargets());
    },

    saveTarget: () => async (dispatch, getState) => {

        dispatch({ type: types.SET_LOADING, loading: true });

        let state = getState().targetReducer;
        const formattedDate = formatISO(state.selectedDate, { representation: 'date' });

        try {
            if (state.editingType === "details")
                await axios.post(`${apiUrl}/settargetinfo`, state.editingTarget);
            else
                await axios.post(`${apiUrl}/settarget/${formattedDate}`, state.editingTarget);
        }
        catch {
            // ignore
        }

        dispatch({ type: types.SET_TARGET_EDITING, targetId: null, editingType: null });
        dispatch(getTargets());
    },

    populateTargets: () => async (dispatch, getState) => {
        dispatch({ type: types.SET_LOADING, loading: true });

        let state = getState().targetReducer;
        const formattedDate = formatISO(state.selectedDate, { representation: 'date' });

        await axios.get(`${apiUrl}/populateTargets/${formattedDate}`);
        dispatch(getTargets());
    }
};

const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === types.SET_TARGET_PILLAR_ID) {
        return {
            ...state,
            pillarId: action.pillarId,
            view: "scorecard",
        };
    }

    if (action.type === types.SET_TARGET_AREA_ID) {
        return {
            ...state,
            areaId: action.areaId
        };
    }

    if (action.type === types.SET_LOADING) {
        return {
            ...state,
            loading: action.loading,
        };
    }

    if (action.type === types.SET_PILLARS) {
        return {
            ...state,
            pillars: action.pillars,
            pillarId: action.pillars[0].id
        };
    }

    if (action.type === types.SET_AREAS) {
        return {
            ...state,
            areas: action.areas
        };
    }

    if (action.type === types.SET_TARGETS) {
        return {
            ...state,
            loading: false,
            targets: action.targets,
        };
    }
    
    if (action.type === types.SET_SELECTED_DATE) {
        return {
            ...state,
            selectedDate: action.date,
        };
    }

    if (action.type === types.SET_TARGET_VIEW) {
        return {
            ...state,
            view: action.view,
        };
    }

    if (action.type === types.SET_TARGET_EDITING) {        

        let editingTarget = action.targetId === null
            ? { targetType: 0 }
            : state.targets.find((x) => x.targetId === action.targetId);

        if (!editingTarget.childTargetIds)
            editingTarget.childTargetIds = state.targets.filter(t => t.rollupTargetId === editingTarget.targetId).map(t => t.targetId);

        return {
            ...state,
            editingTarget,
            editingType: action.editingType,
        };
    }

    if (action.type === types.SET_TARGET_VALUE) {
        console.log('x', action);
        return {
            ...state,
            editingTarget: {
                ...state.editingTarget,
                [action.field]: action.value,
            },
        };
    }

    return state;
};

export default reducer;