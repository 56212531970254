import React, { useState, useMemo, useRef } from 'react';
import { CSVLink } from "react-csv";
import {
    Box,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    TextField,
    Switch,
    Button
} from "@material-ui/core";
import { useGetJobTitlesQuery, useUpdateJobTitleMutation, useUpdateJobTitlesMutation } from "../../store/pardotApiSlice";

const JobTitles = ({ filter, setFilter }) => {

    const { data: jobTitles, isLoading } = useGetJobTitlesQuery();
    const [page, setPage] = useState(0);

    const [updateJobTitle] = useUpdateJobTitleMutation();
    const [updateJobTitles, { isLoading: saving }] = useUpdateJobTitlesMutation();

    const fileInputRef = useRef();

    const activeFilter = useMemo(() => {
        if (filter.length < 3)
            return "";

        setPage(0);

        return filter.toLowerCase();
    }, [filter]);

    const rowsPerPage = 10;

    const filteredJobTitles = useMemo(() => {

        let filtered = jobTitles || [];

        if (activeFilter !== "")
            filtered = filtered.filter(f => f.title.toLowerCase().includes(activeFilter));

        return filtered;

    }, [activeFilter, jobTitles]);

    const displayedJobtitles = useMemo(() => {

        let displayed = filteredJobTitles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        return displayed;
    }, [filteredJobTitles, page]);

    const csvHeaders = [
        { label: "Job Title", key: "title" },
        { label: "Is SDM?", key: "isSDM" }
    ];

    const handleUploadCSV = async (newFile) => {
        if (newFile.target.files[0]) {
            const formData = new FormData();
            formData.append("file", newFile.target.files[0]);

            updateJobTitles(formData);
        }
    };

    const csvData = useMemo(() => {
        if (!jobTitles)
            return [];

        var data = jobTitles.map(x => ({
            title: x.title.replace("\"", "\"\""),
            isSDM: x.isSDM ? 1 : 0
        }))

        console.log({ data });

        return data;

    }, [jobTitles]);

    return (
        <>
            <Box display="flex" style={{ marginTop: '10px' }} alignItems="center" gridGap="10px" flexWrap="wrap">
                <TextField
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    variant="outlined"
                    size="small"
                    label="filter"
                />
                <CSVLink
                    headers={csvHeaders}
                    data={csvData}
                    filename={`JobTitles.csv`}
                >
                    <Button
                        variant="contained"
                        color="primary"
                    >
                        Export CSV
                    </Button>
                </CSVLink>
                <Button
                    onClick={() => fileInputRef.current.click()}
                    variant="contained"
                    color="primary" >
                    Upload CSV
                </Button>
                <input
                    hidden
                    ref={fileInputRef}
                    accept="text/csv"
                    type="file"
                    onChange={handleUploadCSV}
                />
            </Box>
            {isLoading && <h2 style={{ margin: '10px' }}>Loading ...</h2>}
            {jobTitles && jobTitles.length === 0 && <h2 style={{ margin: '10px' }}>No entries</h2>}
            {jobTitles && jobTitles.length > 0 &&
                <Table size="small">
                    <TableHead>
                        <TableRow style={{ height: '37px' }}>
                            <TableCell>Title</TableCell>
                            <TableCell>Is SDM?</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedJobtitles.map(jt =>
                            <TableRow key={jt.title}>
                                <TableCell>{jt.title}</TableCell>
                                <TableCell>
                                    <Switch
                                        disabled={saving}
                                        checked={jt.isSDM || false}
                                        onChange={() => updateJobTitle({ title: jt.title, isSDM: !jt.isSDM })}
                                        color="primary"
                                    /></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                count={filteredJobTitles.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(e, newPage) => setPage(newPage)}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            }
        </>
    )
};

export default JobTitles;
