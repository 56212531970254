import { configureStore } from '@reduxjs/toolkit'
import { pardotApi } from './pardotApiSlice'
import userReducer from './userReducer';
import snackReducer from "./snackReducer";
import configReducer from "./configReducer";
import targetReducer from "./targetReducer";


const store = configureStore({    
    reducer: {
        [pardotApi.reducerPath]: pardotApi.reducer,
        userReducer,
        snackReducer,
        configReducer,
        targetReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(pardotApi.middleware)
})

export default store;

