import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import styled from "styled-components";
import { red, green } from "@material-ui/core/colors";
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/configReducer';

import {
    Typography,
    Card as MuiCard,
    CardContent as MuiCardContent,
    TextField,
    Box,
    Button,
    Chip,
    ButtonGroup
} from '@material-ui/core';

const Card = styled(MuiCard)`
    border: 1px solid #1b569d;
    margin: 8px;
`;

const CardContent = styled(MuiCardContent)`
    padding: 8px !important;
`;

const QuestionCard = ({ question, setValue, sections, placeholders }) => {     

    const fullQuestion = useMemo(() => {
        let result = {};

        let matched;
        if (question.type === "placeholder") {
            matched = placeholders.filter(x => x.key === question.key);
        }
        if (question.type === "section") {
            matched = sections.filter(x => x.sectionId === question.id);
        }
        if (matched.length > 0)
            result = matched[0];

        return { ...question, ...result };
    }, [placeholders, sections, question]);

    const questionSelected = () => {
        document.querySelector(`[data-key="${question.key}"]`).scrollIntoView();        
    };    

    return (
        <Card onClick={questionSelected}>
            <CardContent className="ew-back">
                <Box display="flex">
                    <Typography>{question.key}</Typography>
                </Box>
            </CardContent>
            <CardContent>
                <Box display="flex" flexDirection="column">
                    <Box><i>{fullQuestion.question}</i></Box>
                    {
                        question.type === "placeholder" &&
                        <TextField
                            fullWidth
                            value={question.value || ""}
                            onChange={e => setValue(e.target.value)}
                            m={2}
                        />
                    }
                    {
                        question.type === "section" && !fullQuestion.isMandatory &&
                        <ButtonGroup>
                            <Button color="primary" variant={question.value === "No" ? "contained" : ""} onClick={() => setValue("No")}>No</Button>
                            <Button color="primary" variant={question.value === "Yes" ? "contained" : ""} onClick={() => setValue("Yes")}>Yes</Button>
                        </ButtonGroup>
                    }
                    <Box display="flex" justifyContent="flex-end" className="ew" style={{ marginTop: '8px' }}>
                        <Chip
                            size="small"
                            style={{ color: 'white', backgroundColor: (fullQuestion.isMandatory || question.value) ? green[500] : red.A700 }}
                            label={fullQuestion.isMandatory ? "Mandatory" : question.value ? "Done" : "Pending"}
                        />
                    </Box>
                </Box>
            </CardContent>
        </Card>


    );
};

const mapStateToProps = state => ({
    sections: Object.values(state.configReducer.sections),
    placeholders: state.configReducer.placeholders
});

const mapDispatchToProps = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCard);

