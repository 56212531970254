import * as types from './constants';

export const actions = {
    setSnackAction: (message, severity) => {
        return {
            type: types.SET_SNACK,
            snack: {
                message: message,
                severity: severity
            }
        }
    },

    removeSnack: () => {
        return {
            type: types.REMOVE_SNACK
        }
    }
}

const initialState = {
    snack: {},
    open: false
};

const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === types.SET_SNACK) {
        return {
            ...state,
            snack: action.snack,
            open: true
        };
    }

    if (action.type === types.REMOVE_SNACK) {
        return {
            ...state,
            snack: {},
            open: false
        };
    }
    return state;
};

export default reducer;