import React, { useEffect, useCallback, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/targetReducer';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { format, addMonths } from 'date-fns'
import ragColors from '../../constants/ragColors';
import EditTargetActual from "./EditTargetActual";
import EditTargetLimits from "./EditTargetLimits";
import EditTargetInfo from "./EditTargetInfo";
import BalancedScoreCard from "./BalancedScoreCard";
import { withStyles } from '@material-ui/core/styles';
import TargetTableHeader from './TargetTableHeader';
import TargetTableRow from './TargetTableRow';
import { setPillarIdAction, setSelectedDateAction, setViewAction, setEditingAction, setAreaIdAction } from '../../store/actions/targetActions';

import {
    Box,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Typography,
    Button,
    Dialog,
    IconButton,
    DialogActions,
    Grid,
    Tabs as MuiTabs,
    Tab
} from "@material-ui/core";

import {
    TrackChanges as TargetIcon,
    ChevronLeft,
    ChevronRight
} from "@material-ui/icons"

const StyledButton = withStyles({
    root: {
        padding: '6px 16px',
        margin: '5px',
        "&.MuiButton-contained": {
            backgroundColor: '#8c72f2',
            color: 'white'
        }
    }
})(Button);

const Tabs = withStyles({
    root: {
        color: '#8c72f2',
        '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
        }
    },
    indicator: {
        backgroundColor: '#8c72f2',
    }
})(MuiTabs);

const useStyles = makeStyles(() => ({
    table: {
        color: '#0a0047',
        "& tbody tr": {
            borderTop: '2px solid #d6d3e3'
        },
        "& td": {
            paddingLeft: '5px',
            height: '59px'
        },
        "& th": {
            paddingLeft: '5px'
        }
    }
}));

const Targets = ({ user, targetState, setPillarId, getTargets, setSelectedDate, setView, setEditing, saveTarget, populateTargets, setAreaId }) => {

    const { selectedDate, targets, loading, pillarId, view, editingTarget, editingType, pillars, areas, areaId } = targetState;

    const classes = useStyles();

    // for view by pillar
    const pillarTargets = useMemo(() => targets.filter(x => x.pillarId === pillarId), [targets, pillarId]);
    const areasWithTargets = useMemo(() => areas.filter(a => pillarTargets.map(t => t.areaId).includes(a.id)), [areas, pillarTargets]);

    // for view by area
    const areaTargets = useMemo(() => targets.filter(x => x.areaId === areaId), [targets, areaId]);
    const pillarsWithTargets = useMemo(() => pillars.filter(p => areaTargets.map(t => t.pillarId).includes(p.id)), [pillars, areaTargets]);

    const pillar = pillars.find(p => p.id === pillarId);
    const area = areas.find(a => a.id === areaId) || { name: 'Roll up' };

    useEffect(() => {
        if (user == null)
            return;
        getTargets();
    }, [user, getTargets, selectedDate]);

    const ragColor = useCallback(target => {

        if (target.actual === "")
            return ragColors.NONE;

        if (target.amberLower == null && target.amberUpper == null && target.redLower == null && target.redUpper == null)
            return ragColors.NONE;

        if ((target.redUpper && target.actual >= target.redUpper) || (target.redLower && target.actual <= target.redLower))
            return ragColors.RED;

        if ((target.amberUpper && target.actual >= target.amberUpper) || (target.amberLower && target.actual <= target.amberLower))
            return ragColors.AMBER;

        return ragColors.GREEN;

    }, []);

    const isValid = () => {

        if (!editingTarget)
            return false;

        if (editingType === "details") {
            return editingTarget.description && editingTarget.areaId !== undefined && editingTarget.pillarId;
        }

        if (editingType === "limits") {
            return (editingTarget.target || editingTarget.target === 0 || editingTarget.areaId === 0) && editingTarget.ownerEmail;
        }

        // actual
        if (ragColor(editingTarget) === ragColors.GREEN)
            return true;

        if (editingTarget.fact && editingTarget.cause && editingTarget.action)
            return true;

        return false;
    };

    if (!user)
        return null;

    return (
        <Box display="flex" flexDirection="column" style={{ padding: '10px', backgroundColor: 'white' }} >
            <Box display="flex" alignItems="center">
                <TargetIcon fontSize="large" style={{ marginRight: '8px', color: '#7051ef', backgroundColor: '#f2edff', padding: '5px', borderRadius: '17px' }} />
                <Typography style={{ margin: '10px', fontSize: '36px', fontWeight: 500 }}>Targets</Typography>
                <StyledButton variant={(view === "balanced" ? "contained" : "text")} onClick={() => setView("balanced")}>Balanced Scorecard</StyledButton>
                <StyledButton variant={(view === "scorecard" ? "contained" : "text")} onClick={() => setView("scorecard")}>Scorecard Breakdown</StyledButton>
                <StyledButton variant={(view === "scorecard2" ? "contained" : "text")} onClick={() => setView("scorecard2")}>Scorecard Breakdown (new)</StyledButton>
                <Box flexGrow={1}></Box>
                <Box display="flex" alignItems="center">
                    <IconButton onClick={() => setSelectedDate(addMonths(selectedDate, -1))}><ChevronLeft /></IconButton>
                    <Typography style={{ width: '110px', textAlign: 'center' }}>{format(selectedDate, 'MMMM yyyy')}</Typography>
                    <IconButton onClick={() => setSelectedDate(addMonths(selectedDate, 1))}><ChevronRight /></IconButton>
                </Box>
            </Box>
            {
                view === "balanced" &&
                <Grid container spacing={3}>
                    {
                        pillars.map(p => <Grid key={p.id} item xs={6}><BalancedScoreCard pillarId={p.id} /></Grid>)
                    }
                </Grid>
            }
            {
                view === "scorecard" &&
                <Box display="flex" flexDirection="column">
                    <Tabs value={pillarId} onChange={(e, pillarId) => setPillarId(pillarId)} style={{ marginBottom: '10px' }}>
                        {
                            pillars.map(p => <Tab value={p.id} key={p.id} label={p.name} />)
                        }
                    </Tabs>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ margin: '10px 5px', fontWeight: 500, fontSize: '20px' }}>{pillar.name}</Typography>
                        <Box display="flex" gridGap="10px">
                            <Button style={{ color: '#8c72f2' }} variant="outlined" onClick={populateTargets}>Populate Targets</Button>
                            <Button style={{ color: '#8c72f2' }} variant="outlined" onClick={() => setEditing(null, "details")}>Add New Metric</Button>
                        </Box>
                    </Box>
                    {
                        loading &&
                        <h2 style={{ margin: '10px' }}>Loading ...</h2>
                    }
                    {
                        !loading && pillarTargets.length === 0 &&
                        <h2 style={{ margin: '10px' }}>No entries</h2>
                    }
                    {
                        !loading &&
                        <Table className={classes.table} size="small">
                            <TargetTableHeader view="scorecard" />
                            {
                                areasWithTargets.map(a => (
                                    <React.Fragment key={a.id}>
                                        <TableHead>
                                            <TableRow style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }}>
                                                <TableCell colSpan={7} style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{a.name}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>{pillarTargets.filter(t => t.areaId === a.id).map(t => <TargetTableRow key={t.targetId} view="scorecard" target={t} />)}</TableBody>
                                    </React.Fragment>
                                ))
                            }
                        </Table>
                    }
                </Box>
            }
            {
                view === "scorecard2" &&
                <Box display="flex" flexDirection="column">
                    <Tabs value={areaId} onChange={(e, areaId) => setAreaId(areaId)} style={{ marginBottom: '10px', flexWrap: 'wrap' }} >
                        <Tab value={0} key="0" label="Roll up" />
                        {
                            areas.map(a => <Tab value={a.id} key={a.id} label={a.name} />)
                        }
                    </Tabs>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ margin: '10px 5px', fontWeight: 500, fontSize: '20px' }}>{area.name}</Typography>
                        <Box display="flex" gridGap="10px">
                            <Button style={{ color: '#8c72f2' }} variant="outlined" onClick={populateTargets}>Populate Targets</Button>
                            <Button style={{ color: '#8c72f2' }} variant="outlined" onClick={() => setEditing(null, "details")}>Add New Metric</Button>
                        </Box>
                    </Box>
                    {
                        loading &&
                        <h2 style={{ margin: '10px' }}>Loading ...</h2>
                    }
                    {
                        !loading && areaTargets.length === 0 &&
                        <h2 style={{ margin: '10px' }}>No entries</h2>
                    }
                    {
                        !loading &&
                        <Table className={classes.table} size="small">
                            <TargetTableHeader view="scorecard" />
                            {
                                pillarsWithTargets.map(p => (
                                    <React.Fragment key={p.id}>
                                        <TableHead>
                                            <TableRow style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }}>
                                                <TableCell colSpan={7} style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{p.name}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>{areaTargets.filter(t => t.pillarId === p.id).map(t => <TargetTableRow key={t.targetId} view="scorecard" target={t} />)}</TableBody>
                                    </React.Fragment>
                                ))
                            }
                        </Table>
                    }
                </Box>
            }
            <Dialog
                open={!!editingType && !loading}
                onClose={() => true}
                maxWidth="xs"
                fullWidth
            >
                {editingType === "actual" && <EditTargetActual />}
                {editingType === "limits" && <EditTargetLimits />}
                {editingType === "details" && <EditTargetInfo />}
                <DialogActions>
                    <Button onClick={() => setEditing(null, null)} color='default'>Cancel</Button>
                    <Button onClick={saveTarget} color='primary' disabled={!isValid(editingTarget)}>Save</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
};

const mapStateToProps = state => ({ user: state.userReducer.user, targetState: state.targetReducer });

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setPillarId: (section) => dispatch(setPillarIdAction(section)),
        setAreaId: (section) => dispatch(setAreaIdAction(section)),
        setSelectedDate: (date) => dispatch(setSelectedDateAction(date)),
        setView: (view) => dispatch(setViewAction(view)),
        setEditing: (target, type) => dispatch(setEditingAction(target, type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Targets);
